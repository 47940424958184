@import './bootstrap.min.css';
@import './buttons.css';

* {
  font-family: 'century Gothic', Helvetica, sans-serif;
}
.right {
  text-align: right;
}
.check {
  color: green !important;
  font-weight: 600;
}
.topFixedBanner {
  background-image: url('../image/bta-planet.png');

  min-height: 550px;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.table > :not(:first-child) {
  border-top: 1px solid #ccc;
}
.topBannerOverlay {
  position: absolute;
  min-height: 550px;
  width: 100%;
  background: rgba(0, 0, 0, 0.2);
}

.topContent {
  margin-top: 15em;
}

.topTitle {
  color: #fff;
  font-weight: 500;
  font-size: 48px;
  text-shadow: 2px 2px #2d3b4d;
}

.image-style-align-left {
  float: left;
  margin-right: 5px;
}

.image-style-side {
  float: right;
  margin-left: 5px;
}
.toSubTitle {
  color: #fff;
  font-size: 22px;
  text-shadow: 1px 1px #2d3b4d;
}
.iconOne {
  height: 80px;
  width: 80px;
}
.serviceCard {
  margin: 5px;
  height: 22rem;
  padding: 60px 30px;
  box-shadow: 0 0 8px 0 rgba(4, 55, 105, 0.2);
}
.serviceCardSmall {
  margin: 2px;
  height: 10rem;
  padding: 5px 10px;
  box-shadow: 0 0 8px 0 rgba(4, 55, 105, 0.2);
  background-color: rgb(253, 253, 238);
}

.serviceCardSmall:hover {
  box-shadow: 0 0 16px 0 rgba(4, 55, 105, 0.2);
}

.serviceCard:hover {
  margin: 5px;
  height: 22rem;
  padding: 60px 30px;
  box-shadow: 0 0 16px 0 rgba(4, 55, 105, 0.2);
}
.serviceName {
  color: rgba(143, 8, 8, 0.7);
  margin-top: 1rem;
  font-size: 22px;
  font-weight: 600;
}

.pName {
  color: rgba(143, 8, 8, 0.7);
  margin-top: 0.4rem;
  margin-bottom: 0.1rem;

  font-weight: 600;
}
.serviceDescription {
  margin-top: 1rem;
  font-size: 15px;
}

.blogCard {
  margin: 5px;
  box-shadow: 0 0 8px 0 rgba(4, 55, 105, 0.2);
}

.peerCard {
  margin: 5px;
  box-shadow: 0 0 8px 0 rgba(4, 55, 105, 0.2);
  border-radius: 10px;
  height: 220px;
}

.peerCard .card-footer {
  background-color: #fff;
  border-radius: 10px;
  border-top: 0px;
}
.blogCard:hover {
  margin: 5px;
  box-shadow: 0 0 16px 0 rgba(4, 55, 105, 0.2);
}

.blogName {
  color: rgba(143, 8, 8, 0.7);
  margin-top: 1rem;
  font-size: 20px;
  font-weight: 600;
}
.blogDescription {
  color: black;
}
.blogCard a {
  text-decoration: none;
}
.mainTitle {
  color: rgba(143, 8, 8, 0.7);
  margin: 5rem 0 3rem 0;
}
.bottomLine {
  background-color: rgba(143, 8, 8, 0.7);
  height: 3px;
  width: 85px;
  margin: -1.9rem auto 0;
  margin-bottom: 3rem;
}
.navTitle {
  color: #fff !important;
  font-weight: 700;
  font-size: 30px;
}
.navTitleScroll {
  color: rgb(133, 3, 3) !important;
  font-weight: 700;
  font-size: 30px;
}
.logo {
  width: 140px;
}
.navBackground {
  -o-transition: background-color 400ms linear;
  -moz-transition: background-color 400ms linear;
  -webkit-transition: background-color 400ms linear;
  transition: background-color 400ms linear;
  background-color: rgba(0, 0, 0, 0.6) !important;
}
.navBackgroundScroll {
  -o-transition: background-color 400ms linear;
  -moz-transition: background-color 400ms linear;
  -webkit-transition: background-color 400ms linear;
  transition: background-color 400ms linear;
  background-color: rgba(255, 255, 255, 1) !important;
}
.navItem {
  text-decoration: none !important;
  color: #fff;
  font-weight: 500;
  font-size: 16px;
}
.navItemScroll {
  text-decoration: none !important;
  color: #000 !important;
  font-weight: 500;
  font-size: 16px;
}

.navItemDropdown {
  text-decoration: none !important;
  color: rgb(0, 0, 0);
  font-weight: 500;
  font-size: 16px;
}
.navItemDropdownScroll {
  text-decoration: none !important;
  color: #000 !important;
  font-weight: 500;
  font-size: 16px;
}
.footerSection {
  margin-top: 5rem;
  box-shadow: 0 0 8px 0;
  background-color: #0e212d;
}
.footerName {
  color: #ffffff;
  margin-top: 1rem;
  font-family: 'Raleway', sans-serif;
  font-size: 22px;
  font-weight: 600;
}
.social-container {
  margin-top: 30px;
}
a.social {
  margin: 0 1rem;
  transition: transform 250ms;
  display: inline-block;
}
a.social:hover {
  transform: translateY(-2px);
}
a.facebook {
  color: #ffffff;
}
a.youtube {
  color: #ffffff;
}
a.twitter {
  color: #ffffff;
}
.footerDescription {
  color: #e9e8e8;
  margin-top: 1rem;
  font-family: 'Raleway', sans-serif;
  font-size: 15px;
  font-weight: 400;
}

.footerLink {
  text-decoration: none;
  font-family: 'Raleway', sans-serif;
  font-size: 14px;
  font-weight: 600;
  color: #ffffff;
}
.footerLink:hover {
  text-decoration: none;
  color: #ffd900;
}
.copyrightSection {
  padding: 20px 20px 20px 20px;
  background-color: #e73138;
}
.copyrightlink {
  text-decoration: none;
  color: #ffffff;
  font-family: 'Raleway', sans-serif;
  font-size: 15px;
  font-weight: 600;
}
.copyrightlink:hover {
  text-decoration: none;
  color: #ffffff;
}

.topFixedPage {
  background-image: url('../image/page.png');
  min-height: 250px;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.topPageOverlay {
  position: absolute;
  min-height: 250px;
  width: 100%;
  background: rgba(0, 0, 0, 0.3);
}

.topContentPage {
  margin-top: 10em;
}

.topFixedPageDashboard {
  background-image: url('../image/page.png');
  min-height: 80px;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.topPageOverlayDashboard {
  position: absolute;
  min-height: 80px;
  width: 100%;
  background: rgba(0, 0, 0, 0.7);
}
.toPageTitle {
  color: #fff;
  font-weight: 500;
  font-size: 48px;
}
.onboardBanner {
  width: 100%;
}
.subTopPageTitle {
  color: #fff;
  font-size: 14px;
}

.Background {
  background-color: hsl(185, 75%, 39%);
}

.ProfileCard {
  width: 250px;
  border-radius: 20px !important;
  border: none !important;
}

.ProfileCardBody {
  padding-top: 3.5rem !important;
  padding-bottom: 2rem !important;
}

.ProfileCardBackgroundImage {
  width: 250px;
  height: 110px;
  border-top-left-radius: 15px !important;
  border-top-right-radius: 15px !important;
}

.border-top {
  width: 250px;

  border-top-left-radius: 15px !important;
  border-top-right-radius: 15px !important;
}
.link {
  text-decoration: none;
  color: rgb(41, 41, 41);
  font-weight: 200;
  font-size: 12px;
}

.ProfileCardImage {
  width: 96px !important;
  height: 96px !important;
  margin: auto;
  position: absolute;
  top: 54px;
  left: 77px;
  border: 5px white solid;
  border-radius: 48px !important;
}

.TextMuted {
  font-weight: 400;
  font-size: 10px;
  color: hsl(0, 0%, 59%);
}

.TextBold {
  font-size: 18px;
  font-weight: 700;
}

.CardFooter {
  background-color: white !important;
  border-bottom-left-radius: 15px !important;
  border-bottom-right-radius: 15px !important;
}

.FooterP {
  margin-bottom: 0;
  margin-top: 0.5rem;
}
.card-header {
  background-color: #fff;
  border-bottom: 0px solid #ccc;
  border-radius: 10px 10px 0 0 !important;
}

.date {
  font-size: 12px;
  display: flex;
  align-items: flex-end;
  justify-content: left;
}

.pDescription {
  font-size: 12px;
}

.price {
  display: flex;
  align-items: center;
  justify-content: right;
  color: rgba(143, 8, 8, 0.7);
}
.text-left {
  text-align: left !important;
}
.text-right {
  text-align: right !important;
}
.marBo200 {
  margin-bottom: 100px !important;
}
